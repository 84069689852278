/* Utilities Styles */
/* mixins*/
/* variables*/
/* Colors*/
/* Breakpoints*/
/* Css variables */
@import url("https://fonts.googleapis.com/css?family=Biryani:700,900|Lato");
:root {
  --header-height: 56px;
  --container-width: 100vw;
  /* use map-get to choose between max-width and min-width queries:*/
  /* use map-get to choose between max-width and min-width queries:*/
  /* use map-get to choose between max-width and min-width queries:*/
  /* use map-get to choose between max-width and min-width queries:*/ }
  @media (min-width: 576px) {
    :root {
      --container-width: 540px; } }
  @media (min-width: 768px) {
    :root {
      --header-height: 64px;
      --container-width: 720px; } }
  @media (min-width: 992px) {
    :root {
      --container-width: 960px; } }
  @media (min-width: 1200px) {
    :root {
      --container-width: 1140px; } }

/* font */
#loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1031; }

#loader svg {
  width: auto;
  height: 100px; }

#loader svg polygon {
  stroke: #F21F25;
  stroke-width: 2px;
  stroke-dasharray: 330px;
  stroke-dashoffset: 660px;
  transition: all 0s; }

#loader.run svg polygon {
  stroke-dashoffset: 0;
  transition: all 1s; }

body {
  font-family: 'Lato', sans-serif; }

section {
  position: relative; }

svg {
  max-width: 100%;
  height: auto; }

h2.mixed {
  font-family: 'Biryani', sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 43px; }
  h2.mixed small {
    display: block;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 20px; }

.bg-white {
  background: white; }

.bg-black {
  background: black; }

.btn {
  padding: 10px 35px 6px !important;
  border-radius: 0;
  color: #FFF;
  border-color: #F21F25;
  background-color: #F21F25;
  text-transform: uppercase;
  font-size: 0.85rem;
  font-family: 'Biryani', sans-serif; }
  .btn:hover, .btn:focus, .btn:active {
    color: #FFF;
    border-color: #F21F25 !important;
    background-color: #ea0e14 !important; }
  .btn:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5) !important; }
  .btn-black {
    padding: 10px 35px 6px !important;
    border-radius: 0;
    color: #FFF;
    border-color: #000;
    background-color: #000; }
    .btn-black:hover, .btn-black:focus, .btn-black:active {
      color: #FFF;
      border-color: #000 !important;
      background-color: black !important; }
    .btn-black:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5) !important; }

#main-header {
  background-color: #FFF;
  /* use map-get to choose between max-width and min-width queries:*/
  transition: transform 0.2s; }
  @media (min-width: 768px) {
    #main-header {
      background-color: transparent; } }
  #main-header .navbar-brand svg {
    width: auto;
    height: 38px; }
  #main-header #navbar-main a {
    padding: 10px 15px 6px;
    color: #000;
    text-transform: uppercase;
    font-family: 'Biryani', sans-serif;
    font-weight: 700;
    font-size: 0.85rem;
    /* use map-get to choose between max-width and min-width queries:*/ }
    @media (min-width: 768px) {
      #main-header #navbar-main a {
        color: #FFF;
        text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5); } }
    #main-header #navbar-main a.change-md {
      /* use map-get to choose between max-width and min-width queries:*/
      /* use map-get to choose between max-width and min-width queries:*/ }
      @media (min-width: 768px) {
        #main-header #navbar-main a.change-md {
          color: #000;
          text-shadow: none; } }
      @media (min-width: 992px) {
        #main-header #navbar-main a.change-md {
          color: #FFF;
          text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.7); } }
    #main-header #navbar-main a.change-lg {
      /* use map-get to choose between max-width and min-width queries:*/ }
      @media (min-width: 992px) {
        #main-header #navbar-main a.change-lg {
          color: #000;
          text-shadow: none; } }
  #main-header #navbar-main .btn {
    padding: 10px 35px 6px !important;
    border-radius: 0;
    color: #FFF;
    border-color: #F21F25;
    background-color: #F21F25;
    /* use map-get to choose between max-width and min-width queries:*/ }
    #main-header #navbar-main .btn:hover, #main-header #navbar-main .btn:focus, #main-header #navbar-main .btn:active {
      color: #FFF;
      border-color: #F21F25 !important;
      background-color: #ea0e14 !important; }
    #main-header #navbar-main .btn:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5) !important; }
    @media (min-width: 768px) {
      #main-header #navbar-main .btn {
        padding: 10px 35px 6px !important;
        border-radius: 0;
        color: #FFF;
        border-color: #FFF;
        background-color: #FFF;
        color: #000;
        text-shadow: none; }
        #main-header #navbar-main .btn:hover, #main-header #navbar-main .btn:focus, #main-header #navbar-main .btn:active {
          color: #FFF;
          border-color: #FFF !important;
          background-color: #f2f2f2 !important; }
        #main-header #navbar-main .btn:focus {
          box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5) !important; } }
  #main-header.headroom--not-top, #main-header.not-jumbotron {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    background-color: #FFF; }
    #main-header.headroom--not-top #navbar-main a, #main-header.not-jumbotron #navbar-main a {
      color: #000;
      text-shadow: none; }
    #main-header.headroom--not-top #navbar-main .btn, #main-header.not-jumbotron #navbar-main .btn {
      /* use map-get to choose between max-width and min-width queries:*/ }
      @media (min-width: 768px) {
        #main-header.headroom--not-top #navbar-main .btn, #main-header.not-jumbotron #navbar-main .btn {
          padding: 10px 35px 6px !important;
          border-radius: 0;
          color: #FFF;
          border-color: #F21F25;
          background-color: #F21F25; }
          #main-header.headroom--not-top #navbar-main .btn:hover, #main-header.headroom--not-top #navbar-main .btn:focus, #main-header.headroom--not-top #navbar-main .btn:active, #main-header.not-jumbotron #navbar-main .btn:hover, #main-header.not-jumbotron #navbar-main .btn:focus, #main-header.not-jumbotron #navbar-main .btn:active {
            color: #FFF;
            border-color: #F21F25 !important;
            background-color: #ea0e14 !important; }
          #main-header.headroom--not-top #navbar-main .btn:focus, #main-header.not-jumbotron #navbar-main .btn:focus {
            box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5) !important; } }
  #main-header.headroom--unpinned {
    transform: translateY(-100%); }

#video-container {
  display: none;
  /* use map-get to choose between max-width and min-width queries:*/ }
  @media (min-width: 768px) {
    #video-container {
      display: block;
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      overflow: hidden; }
      #video-container video {
        width: auto;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 0;
        /* use map-get to choose between max-width and min-width queries:*/ } }

#main-jumbotron {
  position: relative;
  background-color: #F21F25;
  overflow: hidden;
  z-index: 1;
  /* use map-get to choose between max-width and min-width queries:*/ }
  @media (min-width: 576px) {
    #main-jumbotron {
      background-color: rgba(255, 255, 255, 0.94);
      padding-top: var(--header-height); } }
  #main-jumbotron > span svg {
    display: block;
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 45vh;
    left: 0;
    transform: rotate(90deg);
    /* use map-get to choose between max-width and min-width queries:*/ }
    @media (min-width: 576px) {
      #main-jumbotron > span svg {
        width: 70vw;
        height: auto;
        min-height: 100%;
        top: 0;
        left: 33.5vw;
        bottom: initial;
        transform: rotateY(180deg) rotateX(180deg); } }
  #main-jumbotron .container {
    min-height: 100vh;
    /* use map-get to choose between max-width and min-width queries:*/ }
    @media (min-width: 768px) {
      #main-jumbotron .container {
        min-height: initial; } }
    #main-jumbotron .container .row {
      height: 100vh;
      /* use map-get to choose between max-width and min-width queries:*/
      /* use map-get to choose between max-width and min-width queries:*/
      /* use map-get to choose between max-width and min-width queries:*/ }
      @media (min-width: 768px) {
        #main-jumbotron .container .row {
          height: 500px; } }
      @media (min-width: 992px) {
        #main-jumbotron .container .row {
          height: 600px; } }
      @media (min-width: 1200px) {
        #main-jumbotron .container .row {
          height: calc(100vh - var(--header-height)); } }
      #main-jumbotron .container .row > div {
        /* use map-get to choose between max-width and min-width queries:*/ }
        @media (min-width: 992px) {
          #main-jumbotron .container .row > div {
            height: 100%; } }
      #main-jumbotron .container .row .logo svg {
        display: block;
        width: 280px;
        height: auto;
        margin: 0 auto 25px;
        /* use map-get to choose between max-width and min-width queries:*/
        /* use map-get to choose between max-width and min-width queries:*/
        /* use map-get to choose between max-width and min-width queries:*/ }
        @media (min-width: 576px) {
          #main-jumbotron .container .row .logo svg {
            width: 230px; } }
        @media (min-width: 768px) {
          #main-jumbotron .container .row .logo svg {
            width: 280px; } }
        @media (min-width: 992px) {
          #main-jumbotron .container .row .logo svg {
            width: 320px; } }

#about img {
  display: block;
  width: 200px;
  height: auto;
  margin: 0 auto 20px; }

#about p {
  padding: 0 15px;
  text-align: center; }

#features {
  background-color: #000;
  overflow: hidden;
  /* use map-get to choose between max-width and min-width queries:*/
  color: #FFF; }
  @media (min-width: 992px) {
    #features {
      background-color: transparent; } }
  #features > svg {
    position: absolute;
    width: 100vw;
    height: auto;
    top: 0;
    left: 20vw; }
  #features p {
    margin-bottom: 35px;
    font-size: 1.1rem; }
    #features p strong {
      font-family: 'Biryani', sans-serif;
      font-weight: 700; }

#clients .client {
  display: flex;
  justify-content: center;
  align-items: center; }
  #clients .client img {
    width: auto;
    height: 70px;
    /* use map-get to choose between max-width and min-width queries:*/ }
    @media (min-width: 576px) {
      #clients .client img {
        height: 108px; } }

#products .product h2 {
  position: relative;
  margin-bottom: 40px; }
  #products .product h2 span {
    position: absolute;
    width: 180px;
    height: 2px;
    left: 0;
    bottom: -16px;
    background-color: #F21F25; }

#products .product p {
  margin-bottom: 0; }

#banner {
  position: relative;
  font-family: 'Biryani', sans-serif;
  font-weight: 900;
  overflow: hidden; }
  #banner > svg {
    position: absolute;
    width: auto;
    height: 100%;
    opacity: 0.1; }
    #banner > svg:nth-of-type(1) {
      display: none;
      /* use map-get to choose between max-width and min-width queries:*/ }
      @media (min-width: 576px) {
        #banner > svg:nth-of-type(1) {
          display: initial;
          left: 0;
          transform: translateX(-40%); } }
    #banner > svg:nth-of-type(2) {
      right: 0;
      transform: rotateY(180deg) rotateX(180deg) translateX(-40%); }
  #banner small {
    font-weight: 700; }

#contact-info h2.mixed {
  position: relative;
  font-size: 35px;
  padding-bottom: 10px;
  margin-bottom: 25px; }
  #contact-info h2.mixed::after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 2px;
    right: 0;
    bottom: 0;
    background-color: #F21F25; }
  #contact-info h2.mixed small {
    font-size: 16px; }

#contact-info ul {
  margin: 0; }
  #contact-info ul li {
    position: relative;
    padding-left: 55px;
    font-size: 15px; }
    #contact-info ul li svg {
      position: absolute;
      width: 30px;
      height: auto;
      left: 0;
      top: 50%;
      color: silver;
      transform: translateY(-50%); }

#map_frame {
  position: relative;
  overflow: hidden;
  background-color: #F21F25;
  /* use map-get to choose between max-width and min-width queries:*/ }
  @media (min-width: 768px) {
    #map_frame {
      background-color: transparent; } }
  #map_frame > svg {
    display: none;
    position: absolute;
    width: 100vw;
    height: auto;
    top: 0;
    opacity: 0.95;
    /* use map-get to choose between max-width and min-width queries:*/
    /* use map-get to choose between max-width and min-width queries:*/
    /* use map-get to choose between max-width and min-width queries:*/ }
    @media (min-width: 768px) {
      #map_frame > svg {
        display: block;
        left: 15vw; } }
    @media (min-width: 992px) {
      #map_frame > svg {
        left: 18vw; } }
    @media (min-width: 1200px) {
      #map_frame > svg {
        transform: translateX(0%); } }
  #map_frame .container {
    position: relative; }
  #map_frame h2.mixed {
    font-size: 38px;
    margin-bottom: 30px; }
  #map_frame .form-control {
    padding: 0 10px 5px 0;
    margin-bottom: 10px;
    color: #FFF;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px #FFF;
    border-radius: 0;
    resize: none;
    outline: none;
    box-shadow: none !important; }
    #map_frame .form-control::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #FFF;
      opacity: 0.9;
      /* Firefox */ }
    #map_frame .form-control:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #FFF; }
    #map_frame .form-control::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #FFF; }

#map {
  position: relative;
  height: 300px;
  /* use map-get to choose between max-width and min-width queries:*/ }
  @media (min-width: 768px) {
    #map {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; } }

#main-footer {
  position: relative;
  overflow: hidden; }
  #main-footer .logo svg {
    display: block;
    width: auto;
    height: 50px; }
  #main-footer ul a {
    text-transform: uppercase;
    font-family: 'Biryani', sans-serif;
    font-weight: 700;
    font-size: 0.85rem;
    color: #000; }
    #main-footer ul a.btn {
      padding: 10px 35px 6px !important;
      border-radius: 0;
      color: #FFF;
      border-color: #F21F25;
      background-color: #F21F25; }
      #main-footer ul a.btn:hover, #main-footer ul a.btn:focus, #main-footer ul a.btn:active {
        color: #FFF;
        border-color: #F21F25 !important;
        background-color: #ea0e14 !important; }
      #main-footer ul a.btn:focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5) !important; }

#jumbotron-nosotros {
  position: relative;
  overflow: hidden;
  /* use map-get to choose between max-width and min-width queries:*/ }
  @media (min-width: 992px) {
    #jumbotron-nosotros {
      height: 100vh; } }
  #jumbotron-nosotros > img {
    display: none;
    /* use map-get to choose between max-width and min-width queries:*/ }
    @media (min-width: 768px) {
      #jumbotron-nosotros > img {
        display: block;
        position: absolute;
        width: 70vw;
        height: auto;
        top: 0;
        left: 33.5vw; } }
  #jumbotron-nosotros h1 {
    position: relative; }
    #jumbotron-nosotros h1::after {
      content: '';
      position: absolute;
      width: 175px;
      height: 3px;
      left: -65px;
      bottom: -20px;
      background-color: #F21F25; }

.bg-pattern {
  background-image: url("../img/pattern1.jpg");
  background-position: bottom right;
  background-size: auto 100%;
  background-repeat: no-repeat;
  /* use map-get to choose between max-width and min-width queries:*/ }
  @media (min-width: 992px) {
    .bg-pattern {
      background-size: 100% auto; } }
  .bg-pattern-2 {
    background-position: top right;
    background-image: url("../img/pattern2.jpg"); }

/*# sourceMappingURL=main.css.map */
